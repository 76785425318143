.terminal {
    position: absolute;
    top: 0;
    left: 0;
}

.welcome {
    position: relative;
}

.world {
    position: absolute;
    top: 0;
    left: 0;
}

@keyframes fly-through {
    0% {
        transform: scale(1) rotate(0deg);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(500) rotate(60deg);
    }
}

.animate {
    animation: fly-through 5s ease-in forwards;
}
